import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FacebookIcon from '@material-ui/icons/Facebook';
import AppleIcon from '@material-ui/icons/Apple';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ReactComponent as GoogleIcon } from '../../../assets/signin/google-icon.svg';
import ROUTES from '../../../constants/routes';
import authSvc from '../../../services/authentication';
import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}))(TextField);

const CustomTextField = (props) => {
  const { label, name, fieldProps: defaultFieldProps = {}, helperText } = props;

  let fieldProps = {
    name: name,
    variant: 'outlined',
    fullWidth: true,
    size: 'small',
    ...defaultFieldProps,
  };
  return (
    <>
      {label && (
        <Typography
          color="textSecondary"
          variant="caption"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '4px',
          }}
        >
          {label}
        </Typography>
      )}
      <Field name={name}>
        {({ field, meta }) => (
          <StyledTextField
            error={meta.touched && !!meta.error}
            helperText={
              meta.touched && meta.error ? meta.error : helperText || ''
            }
            {...fieldProps}
            {...field}
          />
        )}
      </Field>
    </>
  );
};

export default CustomTextField;
