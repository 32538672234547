import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Form, Formik, Field, useField } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FacebookIcon from '@material-ui/icons/Facebook';
import AppleIcon from '@material-ui/icons/Apple';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { ReactComponent as GoogleIcon } from '../../../assets/signin/google-icon.svg';
import ROUTES from '../../../constants/routes';
import authSvc from '../../../services/authentication';
import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';
import CustomPasswordField from '../../SignIn/CustomPasswordField';
import useBreakpoints from 'src/hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  radioWrapper: {
    borderColor: theme.palette.grey['400'],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
    color: theme.palette.grey['600'],
  },
  selectedRadioWrapper: {
    backgroundColor: 'rgba(255, 228, 142, 0.19)',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
  },
}));

const fieldName_userType = 'userType';
const fieldName_email = 'email';

function SendVerificationEmailForm(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  console.log('aaa theme ', theme);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(ConfigContext);
  const { smBelow } = useBreakpoints();

  const validationSchema = Yup.object({
    [fieldName_email]: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const formikOnSubmit = async (values, actions) => {
    console.log('values', values);
    // setMsg('');
    // setLoading(true);
    // await authSvc.signin(values).then(
    //   (rs) => {
    //     // if (rs.is_success === true) {
    //     setUser(rs.user);
    //     // }
    //     companySvc.companiesAndShares().then((response) => {
    //       console.log('response', response[0].company_name);
    //       companySvc.setCompany({
    //         companyName: response[0].company_name,
    //         companyId: response[0].company_id,
    //         uen: response[0].company_registration_no,
    //       });
    //       console.log('comps', companySvc.getCompany());
    //     });
    //     setLoading(false);

    //     history.push(ROUTES.DASHBOARD);
    //   },
    //   (err) => {
    //     setMsg(err.message);
    //     setLoading(false);
    //   },
    // );
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          Reset password
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          // width={smBelow ? 'auto' : '275px'}
          width="100%"
        >
          <Box maxWidth="275px" width={'100%'}>
            <Typography color="textSecondary" variant="body2">
              {
                "Don't worry, just enter the email address you used to registered your account and we’ll send you a link to reset your password."
              }
            </Typography>
          </Box>
        </Box>
        <Formik
          initialValues={{
            [fieldName_email]: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            return (
              <Form style={{ width: '100%' }}>
                <Box mb={2}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{
                      display: 'flex',
                      marginBottom: '4px',
                    }}
                  >
                    <span>Email address</span>
                  </Typography>
                  <Field name={fieldName_email}>
                    {({ field, meta }) => (
                      <StyledTextField
                        name={fieldName_email}
                        variant="outlined"
                        placeholder="you@example.com"
                        fullWidth
                        size="small"
                        {...field}
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                            ? meta.error
                            : props.helperText || ''
                        }
                      />
                    )}
                  </Field>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  // disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  // variant="contained"
                  style={{
                    color: '#FFFFFF',
                    textTransform: 'initial',
                    fontWeight: 700,
                  }}
                  size="large"
                  fullWidth={true}
                  disableElevation
                >
                  Reset password
                </Button>
                {msg && <p>{msg}</p>}
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={loading ? 2 : 0}
                >
                  <ProgressLoader loading={loading} />
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Box display="flex" alignItems="center" justifyContent="center" p={3}>
          <Typography color="textSecondary" variant="body2" align="center">
            <Link href="#" color="primary">
              Contact us
            </Link>{' '}
            if resetting your password does not work.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: theme.shape.borderRadius,
      // },
      backgroundColor: '#fff',
      paddingRight: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}))(TextField);

SendVerificationEmailForm.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SendVerificationEmailForm;
