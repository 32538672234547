import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const productContext = createContext({
  products: [],
  product: {},
  productError: '',
  productLoading: false,
  productSubmitting: false,
  ListProducts: () => Promise.resolve({}),
  ReadProduct: () => Promise.resolve({}),
});

export function useProvideProduct() {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [productError, setProductError] = useState();
  const [productLoading, setProductLoading] = useState(false);
  const [productSubmitting, setProductSubmitting] = useState(false);

  const ListProducts = async () => {
    const res = await APIRequest({
      setError: setProductError,
      setLoading: setProductLoading,
      path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/products`,
      method: 'get',
    });
    if (res.status === 'success') {
      setProducts(res.body.products);
    }
    return res;
  };

  const ReadProduct = async (productID) => {
    const res = await APIRequest({
      setError: setProductError,
      setLoading: setProductLoading,
      path: `https://9qta428pn8.execute-api.ap-southeast-1.amazonaws.com/Prod/product/${productID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setProduct(res.body.product);
    }
    return res;
  };

  return {
    products,
    product,
    productError,
    productLoading,
    productSubmitting,
    ListProducts,
    ReadProduct,
  };
}

export const useProductContext = () => useContext(productContext);

export function ProvideProduct({ children }) {
  const product = useProvideProduct();
  return (
    <productContext.Provider value={product}>
      {children}
    </productContext.Provider>
  );
}
