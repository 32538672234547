import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const requestContext = createContext({
  requestError: '',
  requestLoading: false,
  requestSubmitting: false,
  CreateBusinessActivityApplication: () => Promise.resolve({}),
  CreateCompanyAddressApplication: () => Promise.resolve({}),
  CreateCompanyNameApplication: () => Promise.resolve({}),
  CreateFinancialYearEndApplication: () => Promise.resolve({}),
  CreateOfficerApplication: () => Promise.resolve({}),
  CreateCessationApplication: () => Promise.resolve({}),
  CreateShareTransferApplication: () => Promise.resolve({}),
  CreateShareAllotmentApplication: () => Promise.resolve({}),
  CreateUpdateParticularsApplication: () => Promise.resolve({}),
  CreateOtherRequestApplication: () => Promise.resolve({}),
  CreateDeclareInterimDividend: () => Promise.resolve({}),
  CreateOpenBankAccApplication: () => Promise.resolve({}),
  CreateCloseBankAccApplication: () => Promise.resolve({}),
});

export function useProvideRequest() {
  const [requestError, setRequestError] = useState();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestSubmitting, setRequestSubmitting] = useState(false);

  const CreateBusinessActivityApplication = async (businessActivity = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.apiURL}/bizactivity/businessactivityapplications/`,
      method: 'post',
      body: businessActivity,
    });
    return res;
  };

  const CreateCompanyAddressApplication = async (
    companyAddrApplication = {},
  ) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.requestURL}/companyaddressapplications/`,
      method: 'post',
      body: companyAddrApplication,
    });
    return res;
  };

  const CreateCompanyNameApplication = async (companyNameApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.requestURL}/companynameapplications/`,
      method: 'post',
      body: companyNameApplication,
    });
    return res;
  };

  const CreateCessationApplication = async (cessationApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.requestURL}/cessationapplications/`,
      method: 'post',
      body: cessationApplication,
    });
    return res;
  };

  const CreateFinancialYearEndApplication = async (
    financialYearEndAppt = {},
  ) => {
    console.log('financialYearEndAppt', financialYearEndAppt);

    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.apiURL}/application/financialyearendapplications/`,
      method: 'post',
      body: financialYearEndAppt,
    });
    return res;
  };

  const CreateDeclareInterimDividend = async (dividend = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.apiURL}/application/dividenddeclarationapplications/`,
      method: 'post',
      body: dividend,
    });
    return res;
  };

  const CreateOpenBankAccApplication = async (openApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.apiURL}/application/openbankaccountapplications/`,
      method: 'post',
      body: openApplication,
    });
    return res;
  };

  const CreateCloseBankAccApplication = async (closeApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.apiURL}/application/closebankaccountapplications/`,
      method: 'post',
      body: closeApplication,
    });
    return res;
  };

  const CreateOtherRequestApplication = async (otherRequestAppt = {}) => {
    // console.log('other request', otherRequestAppt);

    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.apiURL}/application/otherrequestapplications/`,
      method: 'post',
      body: otherRequestAppt,
    });
    return res;
  };

  const CreateOfficerApplication = async (officerApplication = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.requestURL}/newofficerapplications/`,
      method: 'post',
      body: officerApplication,
    });
    return res;
  };

  const CreateShareTransferApplication = async (shareTransfer = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.requestURL}/sharetransferapplications/`,
      method: 'post',
      body: shareTransfer,
    });
    return res;
  };

  const CreateShareAllotmentApplication = async (shareAllotment = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.requestURL}/sharesallotmentapplications/`,
      method: 'post',
      body: shareAllotment,
    });
    return res;
  };

  const CreateUpdateParticularsApplication = async (personParticulars = {}) => {
    const res = await APIRequest({
      setError: setRequestError,
      setLoading: setRequestSubmitting,
      path: `${Config.requestURL}/individualparticularapplications/`,
      method: 'post',
      body: personParticulars,
    });
    return res;
  };

  return {
    requestError,
    requestLoading,
    requestSubmitting,
    CreateBusinessActivityApplication,
    CreateCompanyAddressApplication,
    CreateCompanyNameApplication,
    CreateCessationApplication,
    CreateFinancialYearEndApplication,
    CreateOfficerApplication,
    CreateShareTransferApplication,
    CreateShareAllotmentApplication,
    CreateUpdateParticularsApplication,
    CreateOtherRequestApplication,
    CreateDeclareInterimDividend,
    CreateOpenBankAccApplication,
    CreateCloseBankAccApplication,
  };
}

export const useRequestContext = () => useContext(requestContext);

export function ProvideRequest({ children }) {
  const request = useProvideRequest();
  return (
    <requestContext.Provider value={request}>
      {children}
    </requestContext.Provider>
  );
}
