import React from 'react';
import { Box, Typography } from '@material-ui/core';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';

const TitleLayout = ({ title, pathList, children }) => {
  return (
    <Box
      px={4}
      py={2}
      display="flex"
      flexDirection="column"
      style={{ minHeight: '85%' }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" pb={2}>
        <Typography variant="h1">{title}</Typography>
        <BreadcrumbsNav pathList={pathList} />
      </Box>

      {children}
    </Box>
  );
};

export default TitleLayout;
