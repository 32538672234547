import React from 'react';
import useStyles from './IconListBox.styles';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import useBreakpoints from '../../hooks/useBreakpoints';
import Slider from 'react-slick';

const settings = {
  infinite: false,
  arrows: false,
  slidesToShow: 5,
  speed: 500,
  rows: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
};

const IconListBox = ({ title, itemData, styles }) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <Box style={styles}>
      {!!title && (
        <Typography variant="h5" align="left" className={classes.mainTitle}>
          {title}
        </Typography>
      )}
      <Box className={classes.mainBox}>
        <Slider {...settings}>
          {itemData.map((item, index) => (
            <Box
              key={'IconListBox' + index}
              classes={{
                root: classes.singleBox,
              }}
            >
              <ButtonBase
                style={{
                  width: '100%',
                  flexDirection: 'column',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 120,
                  color: '#555555',
                  border: '2px solid #BFBFBF',
                  borderRadius: 10,
                }}
              >
                {item.icon}
                <Typography variant="body1" className={classes.iconTitle}>
                  {item.title}
                </Typography>
              </ButtonBase>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default IconListBox;
