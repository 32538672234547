import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    paper: {
      height: 148,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: 10,
      [theme.breakpoints.down('md')]: {
        height: 183,
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    avatar1: {
      backgroundColor: '#FEF3F1',
      color: '#A80601',
      height: 70,
      width: 70,
    },
    avatar2: {
      backgroundColor: '#FFF3CC',
      color: '#FFC300',
      height: 70,
      width: 70,
    },
    avatar3: {
      backgroundColor: '#E3EDFD',
      color: '#143350',
      height: 70,
      width: 70,
    },
  }),
);
