import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
  Card,
  CardContent,
} from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FacebookIcon from '@material-ui/icons/Facebook';
import AppleIcon from '@material-ui/icons/Apple';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ReactComponent as GoogleIcon } from '../../../assets/signin/google-icon.svg';
import ROUTES from '../../../constants/routes';
import authSvc from '../../../services/authentication';
import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';
import CustomTextField from '../CustomTextField';
import CustomPasswordField from '../CustomPasswordField';
import useBreakpoints from 'src/hooks/useBreakpoints';

function SignInForm(props) {
  const history = useHistory();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(ConfigContext);
  const { smBelow } = useBreakpoints();
  const theme = useTheme();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
    rememberMe: Yup.bool(),
  });

  const formikOnSubmit = async (values, actions) => {
    setLoading(true);
    setMsg('');
    await authSvc.signin(values).then(
      (rs) => {
        // if (rs.is_success === true) {
        setUser(rs.user);
        // }
        companySvc.companiesAndShares().then((response) => {
          console.log('response', response[0].company_name);
          companySvc.setCompany({
            companyName: response[0].company_name,
            companyId: response[0].company_id,
            uen: response[0].company_registration_no,
          });
          console.log('comps', companySvc.getCompany());
        });
        setLoading(false);

        history.push(ROUTES.DASHBOARD);
      },
      (err) => {
        setMsg(err.message);
        setLoading(false);
      },
    );
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          Welcome Back
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Typography color="textSecondary" variant="body2">
            New to Meyzer360?&nbsp;
            <Link href="#" color="primary">
              Register an account
            </Link>
          </Typography>
        </Box>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form style={{ width: '100%' }}>
              <Box mb={2}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  style={{
                    display: 'flex',
                    marginBottom: '4px',
                  }}
                >
                  <span>Email address</span>
                </Typography>
                <Field name={'email'}>
                  {({ field, meta }) => (
                    <StyledTextField
                      name="email"
                      variant="outlined"
                      placeholder="you@example.com"
                      fullWidth
                      size="small"
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={
                        meta.touched && meta.error
                          ? meta.error
                          : props.helperText || ''
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box mb={1}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '4px',
                  }}
                >
                  <span>Password</span>
                  <Link href="#" color="primary">
                    Forgot password?
                  </Link>
                </Typography>
                <CustomPasswordField name={'password'} hideStrength={true} />
                {/* <Field name={'password'}>
                {({ field, meta }) => (
                  <StyledTextField
                    type="password"
                    name="password"
                    variant="outlined"
                    placeholder="Enter 8 character or more"
                    fullWidth
                    size="small"
                    {...field}
                    error={meta.touched && !!meta.error}
                    helperText={
                      meta.touched && meta.error
                        ? meta.error
                        : props.helperText || ''
                    }
                  />
                )}
              </Field> */}
              </Box>
              <Box mb={1}>
                <Field name="rememberMe">
                  {({ field, meta }) => (
                    <FormCheckbox
                      name="rememberMe"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      label={
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">
                            Keep me logged in
                          </Typography>
                        </Box>
                      }
                      {...field}
                    />
                  )}
                </Field>
              </Box>
              <Button
                variant="contained"
                color="primary"
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                // variant="contained"
                style={{
                  color: '#FFFFFF',
                  textTransform: 'initial',
                  fontWeight: 700,
                }}
                size="large"
                fullWidth={true}
                disableElevation
              >
                Sign in
                {/* {loading ? <ProgressLoader loading={true} /> : 'Sign in'} */}
              </Button>
              {msg && <p>{msg}</p>}
              <Box display="flex" justifyContent="center" mt={loading ? 2 : 0}>
                <ProgressLoader loading={loading} />
              </Box>
            </Form>
          )}
        </Formik>
        <Box display="flex" alignItems="center" justifyContent="center" p={3}>
          <Typography color="textSecondary" variant="body2">
            Or sign in with
          </Typography>
        </Box>
        {/* <Box> */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#4267B2',
                color: '#ffffff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <FacebookIcon />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#F14336',
                color: '#fff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <SvgIcon style={{ color: '#fff' }}>
                <GoogleIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#000000',
                color: '#ffffff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <AppleIcon />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}))(TextField);

SignInForm.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SignInForm;
