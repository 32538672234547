import React from 'react';
import { Typography, Box, Container } from '@material-ui/core';
import computerImg from '../assets/homepage/computer.png';
import useBreakpoints from 'src/hooks/useBreakpoints';

const Features = ({ textTitle, textDesc, styles }) => {
  const { smBelow, xsBelow } = useBreakpoints();

  return (
    <Container style={styles}>
      <Box display={smBelow ? 'initial' : 'flex'} alignItems="center">
        <Box>
          <Typography variant={smBelow ? 'subtitle1' : 'h6'} align="left">
            {textTitle}
          </Typography>
          <Typography
            variant={smBelow ? 'h5' : 'h4'}
            align="left"
            style={{ paddingTop: 20, fontWeight: 600 }}
          >
            {textDesc}
          </Typography>
        </Box>
        <img
          src={computerImg}
          style={{
            maxHeight: smBelow ? (xsBelow ? 200 : 300) : 'initial',
            paddingTop: 20,
          }}
        />
      </Box>
    </Container>
  );
};

export default Features;
