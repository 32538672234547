import React from 'react';
import useStyles from './HeroContent.styles';
import { Box, Container, Typography } from '@material-ui/core';
import useBreakpoints from '../../hooks/useBreakpoints';

const HeroContent = ({
  bgImg,
  textTitle,
  textDesc,
  overlay,
  gradient,
  centeredTitle,
  containBg,
}) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  return (
    <Box className={centeredTitle ? classes.bgrootCentered : classes.bgroot}>
      <Container
        className={
          centeredTitle ? classes.bgcontainerCentered : classes.bgcontainer
        }
      >
        <Typography variant={smBelow ? 'subtitle1' : 'h6'} align="left">
          {textTitle}
        </Typography>
        <br />
        <Typography variant={smBelow ? 'h4' : 'h3'} align="left">
          {textDesc}
        </Typography>
      </Container>
      {overlay && <div className={classes.backdrop} />}
      {gradient && <div className={classes.gradient} />}
      <div
        className={containBg ? classes.containBackground : classes.background}
        style={{
          backgroundImage: `url(${bgImg})`,
        }}
      />
    </Box>
    // <div
    //   style={{
    //     position: 'relative',
    //   }}
    // >
    //   <img
    //     src={bgImg}
    //     style={{
    //       height: 700,
    //       width: '100%',
    //     }}
    //   />

    //   <div
    //     style={{
    //       position: 'absolute',
    //       top: 0,
    //       left: 0,
    //       width: '100%',
    //       height: '100%',
    //       background: 'rgba(0, 0, 0, 0.6)',
    //     }}
    //   >
    //     <Container
    //       style={{
    //         // position: 'absolute',
    //         bottom: 40,
    //         // left: 270,
    //         color: 'white',
    //         width: '70%',
    //         textAlign: 'left',
    //       }}
    //     >
    //       <p style={{ fontSize: 38 }}>{textTitle}</p>
    //       <p style={{ fontSize: 18 }}>{textDesc}</p>
    //     </Container>
    //   </div>
    // </div>
  );
};

export default HeroContent;
