import {
  AppBar,
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListSubheader,
  Toolbar,
  ListItem,
  Typography,
  Badge,
  ButtonBase,
  Button,
  Select,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MenuIcon from '@material-ui/icons/Menu';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccount';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import BusinessIcon from '@material-ui/icons/Business';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import clsx from 'clsx';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import mlogo from 'src/assets/m360.png';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useStyles from './AuthLayout.styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import authSvc from 'src/services/authentication';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';
import NavItem from 'src/components/NavItem';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { navGroups } from './nav';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import LoadingOverlay from 'react-loading-overlay';
import wavingHandImage from 'src/assets/notifications/waving-hand.png';
import ClipLoader from 'react-spinners/ClipLoader';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const AuthLayout = ({ children, contentStyle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { smBelow } = useBreakpoints();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = useState(true);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openList, setOpenList] = useState({
    ['LEGAL']: location.pathname.includes(ROUTES.CORPSEC),
  });
  const {
    cartList,
    confirmationDialog,
    company,
    companyList,
    setCompany,
    companyLoading,
  } = useContext(ConfigContext);
  const [user, setUser] = useState('');

  const notificationBtnRef = useRef();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([
    {
      imageSrc: '',
      description: 'Request the service of change company particulars.',
      createdAt: '10 mins ago',
    },
    {
      imageSrc: '',
      description: 'Import by creating a new post, photo or video.',
      createdAt: '12 mins ago',
    },
    {
      imageSrc: '',
      description: 'Request the service of change company particulars. ',
      createdAt: '18 mins ago',
    },
    {
      imageSrc: '',
      description: 'Request the service of change company particulars. ',
      createdAt: '18 mins ago',
    },
  ]);

  const handleNotificationToggle = () => {
    setShowNotifications((prevOpen) => !prevOpen);
  };

  const handleNotificationClose = (event) => {
    if (
      notificationBtnRef.current &&
      notificationBtnRef.current.contains(event.target)
    ) {
      return;
    }

    setShowNotifications(false);
  };

  // const handleListClick = (id) => {
  //   setOpenList((prevState) => ({ ...prevState, [id]: !prevState[id] }));

  const [menuSelectedCompany, setMenuSelectedCompany] = React.useState(null);

  const closeMenuSelectedCompany = () => {
    setMenuSelectedCompany(null);
  };

  const handleListClick = (id, open) => {
    setOpenList((prevState) => ({
      ...prevState,
      [id]: open ? true : !prevState[id],
    }));
  };

  function truncate(str, num) {
    if (str) return str.length > 10 ? str.substring(0, num) + '...' : str;
  }

  const NavItems = {
    overview: {
      selected: location.pathname === ROUTES.DASHBOARD,
      label: 'Overview',
      icon: DashboardOutlinedIcon,
      route: ROUTES.DASHBOARD,
      textVariant: 'h5',
    },
    legal: {
      selected: false,
      label: 'Secretarial',
      icon: AccountBalanceIcon,
      onClick: () => {
        handleListClick('LEGAL');
      },
      children: openList['LEGAL'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
      textVariant: 'h5',
    },
    legalDashboard: {
      selected: location.pathname === ROUTES.CORPSEC,
      label: 'Dashboard',
      route: ROUTES.CORPSEC,
    },
    legalRequest: {
      selected: location.pathname.includes(ROUTES.CORPSEC_REQ_APPT_DIRECTOR),
      label: 'Request',
      route: ROUTES.CORPSEC_REQ_APPT_DIRECTOR,
    },
    legalDocument: {
      selected: location.pathname === ROUTES.CORPSEC_DOC,
      label: 'Document',
      route: ROUTES.CORPSEC_DOC,
    },
    legalProfile: {
      selected: location.pathname === ROUTES.CORPSEC_PRO,
      label: 'Product',
      route: ROUTES.CORPSEC,
    },
    legalTasklist: {
      selected: location.pathname === ROUTES.CORPSEC_TASK,
      label: 'Tasklist',
      route: ROUTES.CORPSEC,
    },
    legalBoardroom: {
      selected: location.pathname === ROUTES.CORPSEC_BOARD,
      label: 'Boardroom',
      route: ROUTES.CORPSEC_BOARD,
    },
    legalCapTable: {
      selected: location.pathname === ROUTES.CORPSEC_CAP,
      label: 'Cap Table',
      route: ROUTES.CORPSEC_CAP,
    },
    bankAccount: {
      textVariant: 'h5',
      selected: false,
      label: 'Bank Account',
      icon: LocalAtmIcon,
      onClick: () => {
        handleListClick('BANK_ACCOUNT');
      },
      children: openList['BANK_ACCOUNT'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
    },
    hr: {
      textVariant: 'h5',
      selected: false,
      label: 'Human Resources Management',
      icon: SupervisorAccountOutlinedIcon,
      onClick: () => {
        handleListClick('HR');
      },
      children: openList['HR'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
    },
    vcfo: {
      textVariant: 'h5',
      selected: false,
      label: 'Virtual Chief Financial Officer',
      icon: PermIdentityIcon,
      onClick: () => {
        handleListClick('VCFO');
      },
      children: openList['VCFO'] ? (
        <ExpandMore style={{ color: 'black' }} />
      ) : (
        <ChevronRightIcon style={{ color: 'black' }} />
      ),
    },
    // settings: {
    //   textVariant: 'h5',
    //   selected: false,
    //   label: 'Settings',
    //   icon: SettingIcon,
    //   onClick: () => {
    //     handleListClick('SETTINGS');
    //   },
    //   children: openList['SETTINGS'] ? (
    //     <ExpandMore style={{ color: 'black' }} />
    //   ) : (
    //     <ChevronRightIcon style={{ color: 'black' }} />
    //   ),
    // },
    // logout: {
    //   textVariant: 'h5',
    //   selected: false,
    //   label: 'Logout',
    //   icon: LogoutIcon,
    //   onClick: () => {
    //     handleListClick('LOGOUT');
    //   },
    // },
  };

  const LegalList = [
    NavItems.legalDashboard,
    NavItems.legalRequest,
    NavItems.legalDocument,
    // NavItems.legalProfile,
    // NavItems.legalTasklist,
    NavItems.legalBoardroom,
    NavItems.legalCapTable,
  ];

  useEffect(() => {
    const data = authSvc.getAuthData();
    setUser(data.user);
  }, []);

  const handleClose = () => {
    setOpenDropDown(false);
    setOpenSetting(false);
    setAnchorEl(null);
  };

  const handleSignout = () => {
    companySvc.removeSelectedCompany();
    authSvc.signOut();
    setUser({});
    history.push(`/`);
    window.location.reload();
    setOpenDropDown(false);
  };

  const changePassword = () => {
    history.push(ROUTES.CHANGE_PASSWORD);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (smBelow && open === true) {
      setOpen(false);
    } else if (!smBelow && open === false) {
      setOpen(true);
      setOpenSetting(false);
    }
  }, [smBelow]); // detect resizing only

  const getNavListGroupsMobile = (nav = []) => {
    let result = [];
    if (nav && nav.length > 0) {
      result = nav.map((aNavGroup, index) => {
        const { title, children = [] } = aNavGroup;
        let navGroupItems = [];
        if (children && children.length > 0) {
          children.forEach((anItem, index2) => {
            const {
              title: itemTitle,
              icon: itemIcon,
              children: itemChildren = [],
            } = anItem;

            let DynamicIcon = itemIcon;

            navGroupItems.push(
              <ListItem
                button
                key={index2}
                // className={classes.navGroupItemContainer}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {DynamicIcon ? <DynamicIcon /> : null}
              </ListItem>,
            );
          });
        }

        return navGroupItems;
      });
    }
    return <List>{result}</List>;
  };

  const renderSettingList = () => {
    return (
      <>
        {/* {!smBelow && (
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              history.push(ROUTES.ORDERHISTORY);
            }}
          >
            <FeaturedPlayListOutlinedIcon />
          </IconButton>
        )} */}

        <IconButton
          className={classes.iconButton}
          onClick={() => {
            history.push(ROUTES.PAYMENTCART);
          }}
        >
          <Badge color="error" badgeContent={cartList.length}>
            <ShoppingCartOutlinedIcon />
          </Badge>
        </IconButton>

        <IconButton
          ref={notificationBtnRef}
          className={classes.iconButton}
          onClick={handleNotificationToggle}
        >
          <Badge
            color="error"
            overlap="circular"
            badgeContent=" "
            variant="dot"
          >
            <NotificationsNoneRoundedIcon />
          </Badge>
        </IconButton>
        <Popper
          open={showNotifications}
          anchorEl={notificationBtnRef.current}
          role={undefined}
          transition
          // disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleNotificationClose}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexWrap="no-wrap"
                    style={{
                      // height: '330px',
                      maxHeight: '330px',
                      // width: '350px',
                      maxWidth: smBelow ? '100%' : '330px',
                      // maxWidth: '330px',
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      m={2}
                    >
                      <Box>
                        <Typography variant="body2">Notifications</Typography>
                      </Box>
                      {notifications && notifications.length > 0 && (
                        <Box fontSize="10px" color={theme.palette.primary.main}>
                          <Link
                            onClick={() => {
                              setNotifications([]);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            Clear all
                          </Link>
                        </Box>
                      )}
                    </Box>
                    <Box flexGrow={1} overflow="auto" position="relative">
                      <NotificationsList data={notifications} />
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Box
          display="flex"
          alignItems="center"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpenDropDown(true);
          }}
        >
          <IconButton className={classes.iconButton}>
            <div className={clsx(classes.shape, classes.shapeCircle)} />
            {!smBelow && (
              <Box p={1} display="flex" flexDirection="column">
                <Typography
                  variant="body2"
                  align="left"
                  style={{ fontWeight: 600 }}
                >
                  {`${user.first_name} ${user.last_name}`}
                </Typography>
                <Typography
                  variant="caption"
                  align="left"
                  style={{ opacity: '50%' }}
                >
                  {user.email}
                </Typography>
              </Box>
            )}
          </IconButton>
        </Box>
        {/* <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpenDropDown(true);
          }}
        >
          <Icon icon="ci:settings-filled" className={classes.settingLogo} />
        </IconButton> */}
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}> */}
          <MenuItem
            onClick={() => {
              history.push(ROUTES.ORDERHISTORY);
            }}
          >
            <FeaturedPlayListOutlinedIcon />
            <Box pr={1} />
            My Orders
          </MenuItem>
          <MenuItem onClick={changePassword}>
            <SettingsOutlinedIcon />
            <Box pr={1} />
            Settings
          </MenuItem>
          <MenuItem onClick={handleSignout}>
            <PowerSettingsNewOutlinedIcon />
            <Box pr={1} />
            Log out
          </MenuItem>
        </StyledMenu>
      </>
    );
  };

  const windowDrawer = () => {
    return (
      <>
        <div className={classes.drawerLogo}>
          <img src={mlogo} height={119} />
          {!smBelow && (
            <div className={classes.toolbar}>
              <IconButton
                className={classes.toolbarToggle}
                onClick={handleDrawerClose}
              >
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon className={classes.textlabel} />
                )}
              </IconButton>
            </div>
          )}
        </div>
        <div style={{ overflow: 'auto' }}>
          <Box px={2} height="100%">
            <List
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
              }}
              subheader={<li />}
            >
              <>
                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: '0.688rem',
                    lineHeight: '0',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  MAIN
                </ListSubheader>
                <NavItem {...NavItems.overview} />

                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: '0.688rem',
                    lineHeight: '0',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  COMPLIANCE & CORPORATE
                </ListSubheader>
                <List
                  style={{
                    marginTop: '-8px',
                  }}
                >
                  <NavItem {...NavItems.legal} />

                  <Collapse in={openList['LEGAL']}>
                    <List
                      component="div"
                      disablePadding
                      style={{ width: '100%' }}
                    >
                      {LegalList.map((item) => (
                        <NavItem
                          style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                          key={item.label}
                          {...item}
                        />
                      ))}
                    </List>
                  </Collapse>
                </List>

                {/* <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: '0.688rem',
                    lineHeight: '0',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  BUSINESS BANKING
                </ListSubheader>
                <NavItem {...NavItems.bankAccount} />

                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: '0.688rem',
                    lineHeight: '0',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  HUMAN CAPITAL
                </ListSubheader>
                <NavItem {...NavItems.hr} />

                <ListSubheader
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: '0.688rem',
                    lineHeight: '0',
                    marginTop: '28px',
                  }}
                  disableSticky
                >
                  VIRTUAL C-SUITE
                </ListSubheader>
                <NavItem {...NavItems.vcfo} /> */}

                <Box p={1} />
                <Divider variant="middle" light />
              </>
              <div style={{ flexGrow: 1 }} />
              <Box p={1} />
            </List>
          </Box>
        </div>
      </>
    );
  };

  const miniDrawer = () => {
    return (
      <>
        <div className={classes.drawerLogo}>
          <Box height={119} />
          <div className={classes.toolbar}>
            <IconButton
              className={classes.toolbarToggle}
              onClick={handleDrawerClose}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon className={classes.textlabel} />
              )}
            </IconButton>
          </div>
        </div>
        <div style={{ overflow: 'auto' }}>
          {getNavListGroupsMobile(navGroups)}
        </div>
      </>
    );
  };

  return (
    <LoadingOverlay
      active={companyLoading}
      spinner={<ClipLoader color="white" />}
      styles={{
        wrapper: {
          overflow: companyLoading ? 'hidden' : 'initial',
        },
      }}
    >
      <CssBaseline />
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          colorPrimary="#000000"
        >
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {smBelow && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {!smBelow && !open && <Box mr={'72px'} />}
              {!smBelow &&
                (companyLoading ? (
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <EnhancedSkeleton
                      layout={[
                        {
                          loaders: [
                            { variant: 'rect', height: 100, width: '100%' },
                          ],
                          repeat: 5,
                          mt: 4.25,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <>
                    <BusinessIcon />
                    <Box pr={1} />

                    <Select
                      value={company.company_id}
                      onChange={(event) => {
                        setCompany(event.target.value);
                      }}
                      IconComponent={ExpandMore}
                      disableUnderline
                      className={classes.companyselect}
                    >
                      {companyList.map((item, index) => (
                        <MenuItem
                          key={item.company_name}
                          value={item.company_id}
                        >
                          <Typography variant="h6" noWrap>
                            {truncate(item.company_name, 25)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ))}

              {smBelow && (
                <>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      setMenuSelectedCompany(event.currentTarget);
                    }}
                  >
                    <BusinessIcon />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={menuSelectedCompany}
                    keepMounted
                    open={Boolean(menuSelectedCompany)}
                    onClose={closeMenuSelectedCompany}
                  >
                    {companyList.map((item, index) => (
                      <MenuItem
                        key={item.company_name}
                        onClick={(event) => {
                          setCompany(item.company_id);
                          closeMenuSelectedCompany();
                        }}
                      >
                        <Typography variant="h6" noWrap>
                          {truncate(item.company_name, 25)}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              {renderSettingList()}
            </div>
          </Toolbar>
        </AppBar>
        {!smBelow && (
          <Drawer
            variant="permanent"
            onClick={!open ? handleDrawerOpen : null}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            {open && windowDrawer()}
            {!open && miniDrawer()}
          </Drawer>
        )}

        {smBelow && (
          <Drawer
            open={open}
            onClose={!open}
            onBackdropClick={() => setOpen(false)}
          >
            {windowDrawer()}
          </Drawer>
        )}
        <main className={classes.content} style={contentStyle}>
          <div className={classes.toolbar} />
          <ConfirmationDialog {...confirmationDialog} />
          {children}
        </main>
      </div>
    </LoadingOverlay>
  );
};

export default AuthLayout;

const PopoverContainer = () => {};

const NotificationItem = (props) => {
  const { data = {} } = props;
  const { description, createdAt } = data;
  const theme = useTheme();

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>{/* <ImageIcon /> */}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="body2">{description}</Typography>}
          secondary={
            <span
              style={{
                fontSize: '10px',
                color: theme.palette.primary.main,
              }}
            >
              {createdAt}
            </span>
          }
        />
      </ListItem>
      <Box px={2}>
        <Divider />
      </Box>
    </>
  );
};

const NotificationsList = (props) => {
  const { data = [] } = props;
  const theme = useTheme();

  if (data && data.length > 0) {
    return (
      <List disablePadding={true}>
        {data.map((aNotification, index) => {
          return <NotificationItem key={index} data={aNotification} />;
        })}
      </List>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      p={3}
      textAlign="center"
    >
      <Box
        flexGrow="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
      >
        <Avatar
          style={{
            width: '100px',
            height: '100px',
            padding: theme.spacing(3),
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <img
            src={wavingHandImage}
            style={{ width: '100%', height: '100%' }}
          />
        </Avatar>
      </Box>
      <Box>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          You have no notifications!
        </Typography>
      </Box>
      <Box maxWidth="300px">
        <Typography variant="body2">
          If you will get any notifications we will tell you about it.
        </Typography>
      </Box>
    </Box>
  );
};
