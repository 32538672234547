import axios from './customAxios';
import storageSvc from './storage';
import { setIsAuth } from '../reducer';
import { Config } from 'src/config';

const API_URL = Config.apiURL;

const signin = async ({ email, password }) => {
  const { data } = await axios.get(
    `${API_URL}/utils/loginbyemail/${email}/${password}`,
  );

  if (data && !data.is_success) throw Error(data?.message);

  let user = data.user;

  try {
    const userRs = await axios.get(
      `${API_URL}/usercontact/users/${data.user.user_id}`,
    );

    if (userRs && userRs?.data?.is_success) user = userRs?.data?.users;
  } catch (error) {}

  const res = { ...data, user };
  setIsAuth({ isAuthenticated: true, res });

  storageSvc.setItem('@auth', res);

  return res;
};

const signup = async (user) => {
  const { data } = await axios.post(
    `${API_URL}/usercontact/userspassword/`,
    user,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return data;
};

const changePassword = async (password) => {
  const user = getLocalUser();
  const { data } = await axios.put(
    `${API_URL}/usercontact/userpassword/${user.user_id}`,
    password,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return data;
};

const isAuthenticated = () => {
  const dt = storageSvc.getItem('@auth');

  return dt && dt.user !== null;
};

const getAuthData = () => {
  return storageSvc.getItem('@auth');
};

const getLocalUser = () => {
  return storageSvc.getItem('@auth')?.user;
};

const signOut = () => {
  storageSvc.deleteItem('@auth');
  storageSvc.deleteItem('@selected_company');
};

const authSvc = {
  signin,
  signOut,
  signup,
  changePassword,
  getLocalUser,
  isAuthenticated,
  getAuthData,
};

export default authSvc;
