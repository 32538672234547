import ROUTES from './routes';

export const TAB_DASHBOARD = {
  name: 'DASHBOARD',
  value: 'DASHBOARD',
  companyRequired: true,
  route: ROUTES.CORPSEC,
};
export const TAB_DOCUMENTS = {
  name: 'DOCUMENTS',
  value: 'DOCUMENTS',
  companyRequired: true,
  route: ROUTES.CORPSEC_DOC,
};
export const TAB_REQUESTS = {
  name: 'REQUESTS',
  value: 'REQUESTS',
  companyRequired: true,
  route: ROUTES.CORPSEC_REQ,
};
export const TAB_PROFILE = {
  name: 'PROFILE',
  value: 'PROFILE',
  companyRequired: false,
  route: ROUTES.CORPSEC_PRO,
};
export const TAB_TASKLIST = {
  name: 'TASKLIST',
  value: 'TASKLIST',
  companyRequired: true,
  route: ROUTES.CORPSEC_TASK,
};
export const TAB_BOARDROOM = {
  name: 'BOARDROOM',
  value: 'BOARDROOM',
  companyRequired: true,
  route: ROUTES.CORPSEC_BOARD,
};
export const TAB_CAPTABLE = {
  name: 'CAP TABLE',
  value: 'CAPTABLE',
  companyRequired: true,
  route: ROUTES.CORPSEC_CAP,
};
