import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { useHistory } from 'react-router-dom';
import appointAdmin from 'src/assets/requests/Appointment of Administrator.svg';
import appointDirector from 'src/assets/requests/Appointment of Director.svg';
import resignAdmin from 'src/assets/requests/Resignation of Administrator.svg';
import otherReso from 'src/assets/requests/FilingofOtherResolutions.svg';
import resignDirector from 'src/assets/requests/Resignation of Director.svg';
// import { Icon } from '@iconify/react';
import ROUTES from 'src/constants/routes';
import useStyles from './Requests.styles';

const REQUESTS_LIST = [
  {
    name: 'Change of Officers',
    icon: appointDirector,
    route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
  },
  // {
  //   name: 'Change Personal Particulars',
  //   icon: appointAdmin,
  //   route: ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
  // },
  // {
  //   name: 'Change Company Particulars',
  //   icon: resignDirector,
  //   route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
  // },
  // {
  //   name: 'Change Share Information',
  //   icon: resignAdmin,
  //   route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
  // },
  // {
  //   name: 'Filling of Other Resolutions',
  //   icon: otherReso,
  //   route: ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS,
  // },

  {
    name: 'Change Personal Particulars',
    icon: appointAdmin,
    route: ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
  },
  {
    name: 'Change Company Particulars',
    icon: resignDirector,
    route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
  },
  {
    name: 'Change Share Information',
    icon: resignAdmin,
    route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
  },
  {
    name: 'Filing of Other Resolutions',
    icon: otherReso,
    route: ROUTES.CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS,
  },
];

const Requests = () => {
  const classes = useStyles();
  const history = useHistory();

  const renderList = () =>
    REQUESTS_LIST.map((r, index) => (
      <ListItem
        key={`${r.name}${index}`}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          minHeight: '60px',
          marginBottom: '15px',
        }}
        onClick={() => {
          history.push(r.route);
        }}
        button
      >
        <ListItemIcon>
          <img src={r.icon} height={40} />
        </ListItemIcon>
        <ListItemText primary={r.name} />
        <ListItemSecondaryAction>
          <IconButton>
            <ChevronRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  return (
    <>
      <Typography variant="h6" color="secondary">
        Select Requests
      </Typography>
      <List
        style={{
          width: '100%',
          paddingTop: 20,
        }}
      >
        {renderList()}
      </List>
    </>
  );
};

export default Requests;
