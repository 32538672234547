import { AppBar, Box, Container, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AuthLayout from 'src/components/AuthLayout';
import {
  CorpsecDashboard,
  CorpsecDocuments,
  CorpsecNameBox,
  CorpsecProfile,
  CorpsecRequests,
  CorpsecRequestsApptAdmin,
  CorpsecRequestsApptDirector,
  CorpsecRequestsChangeCompanyParticulars,
  CorpsecRequestsChangeCompanyName,
  CorpsecRequestsChangeCompanyFinancialYearEnd,
  CorpsecRequestsChangeCompanyAddress,
  CorpsecRequestsChangeBusinessActivities,
  CorpsecRequestsChangeShareInformation,
  CorpsecRequestsAllotmentShares,
  CorpsecRequestsTransferShares,
  CorpsecRequestsChangeOtherResolutions,
  CorpsecRequestsDeclareInterimDividend,
  CorpsecRequestsOpenBankAccount,
  CorpsecRequestsCloseBankAccount,
  CorpsecRequestsOtherRequest,
  CorpsecRequestsChangeDirectorParticulars,
  CorpsecRequestsChangeOfficers,
  CorpsecRequestsChangePersonalParticulars,
  CorpsecRequestsChangeShareholderParticulars,
  CorpsecRequestsDirectorList,
  CorpsecRequestsResgnAdmin,
  CorpsecRequestsResgnDirector,
  CorpsecRequestsShareholderList,
  CorpsecSidebar,
  CorpsecTaskList,
  CorpsecBoardRoom,
  CorpsecCapTable,
} from 'src/components/Corpsec';
import ROUTES from 'src/constants/routes';
import useStyles from './corpsec.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';

// DEPRECATED: to deprecate this page for new UI

const Corpsec = () => {
  const classes = useStyles();
  const location = useLocation();
  const [namebox, showNamebox] = useState(true);
  const params = useParams();
  const { mdBelow } = useBreakpoints();

  const setContent = () => {
    if (location.pathname === ROUTES.CORPSEC) {
      return (
        <Box>
          <CorpsecDashboard />
        </Box>
      );
      // return TAB_DASHBOARD.value;
    }

    if (location.pathname === ROUTES.CORPSEC_DOC) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecDocuments />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequests />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_PRO) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecProfile />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_TASK) {
      return (
        <Box
          pt={3}
          display="flex"
          flexDirection="column"
          minWidth={0}
          minHeight={0}
        >
          <CorpsecTaskList />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_BOARD) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecBoardRoom />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_CAP) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecCapTable />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_CHANGE_OFFICER) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeOfficers />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeCompanyParticulars />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_CHANGE_COMPANY_ADDRESS) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeCompanyAddress />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeBusinessActivities />
        </Box>
      );
    }
    if (
      location.pathname === ROUTES.CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END
    ) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeCompanyFinancialYearEnd />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_CHANGE_COMPANY_NAME) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeCompanyName />
        </Box>
      );
    }

    if (location.pathname === ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeShareInformation />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsAllotmentShares />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_TRANSFER_SHARES) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsTransferShares />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_FILLING_OTHER_RESOLUTIONS) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeOtherResolutions />
        </Box>
      );
    }
    if (
      location.pathname === ROUTES.CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND
    ) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsDeclareInterimDividend />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsOpenBankAccount />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsCloseBankAccount />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsOtherRequest />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangePersonalParticulars />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_DIRECTOR_LIST) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsDirectorList />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsShareholderList />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_APPT_DIRECTOR) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsApptDirector />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_APPT_ADMIN) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsApptAdmin />
        </Box>
      );
    }
    if (location.pathname === ROUTES.CORPSEC_REQ_RESGN_ADMIN) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsResgnAdmin />
        </Box>
      );
    }

    if (location.pathname === ROUTES.CORPSEC_REQ_RESGN_DIRECTOR) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsResgnDirector />
        </Box>
      );
    }

    if (
      location.pathname ===
      ROUTES.CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS(params.id)
    ) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeDirectorParticulars />
        </Box>
      );
    }
    if (
      location.pathname ===
      ROUTES.CORPSEC_REQ_CHANGE_SHAREHOLDER_PARTICULARS(params.id)
    ) {
      return (
        <Box
          pt={3}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CorpsecRequestsChangeShareholderParticulars />
        </Box>
      );
    }
  };

  useEffect(() => {
    showNamebox(true);
    if (location.pathname === ROUTES.CORPSEC_PRO) {
      showNamebox(false);
    }
  }, [location.pathname]);

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
        <AppBar
          position="static"
          classes={{
            root: classes.appbar,
          }}
        >
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h5" noWrap>
              CORPORATE SECRETARY
            </Typography>
          </Toolbar>
        </AppBar>

        <Box
          display={mdBelow ? 'initial' : 'flex'}
          flexDirection="row"
          flexGrow={1}
          className={classes.content}
        >
          <CorpsecSidebar />
          <Container>
            {namebox && <CorpsecNameBox />}

            {setContent()}
          </Container>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Corpsec;
