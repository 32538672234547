import React, { useState, useEffect } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  Divider,
  Paper,
  List,
  ListSubheader,
  ListItemSecondaryAction,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import useBreakpoints from '../hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { useProvideOrder } from 'src/hooks/useOrder';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { EnhancedSkeleton } from 'src/components/Enhanced';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
  },
  gradientButton: {
    minWidth: '230px',
    minHeight: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
  fontWeight: {
    fontWeight: 600,
  },

  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      // mobile view
      flexDirection: 'column',
      flexGrow: 0,
    },
  },
  paymentButton: {
    width: 130,
    textTransform: 'initial',
  },
  paymentActiveButton: {
    width: 130,
    textTransform: 'initial',
    backgroundColor: '#C5C5C5',
  },
}));

const OrderHistoryDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { smBelow } = useBreakpoints();
  const { ReadOrder, order, orderLoading } = useProvideOrder();
  const params = useParams();

  useEffect(() => {
    ReadOrder(params.id);
  }, []);

  const TypographyStatus = ({ status }) => {
    let statusColor, textLabel;
    switch (status) {
      case 'completed':
        statusColor = '#24FF00';
        textLabel = 'Completed';
        break;
      case 'PENDING':
        statusColor = '#FF7F09';
        textLabel = 'Pending';
        break;
      case 'paid':
        statusColor = '#041DFF';
        textLabel = 'Paid';
        break;
      case 'processing':
        statusColor = '#FF7F09';
        textLabel = 'Processing';
        break;
      case 'failed':
        statusColor = '#FF4D00';
        textLabel = 'Failed';
        break;
    }
    return (
      <Typography
        variant="body1"
        align="right"
        style={{ fontWeight: 600, color: statusColor }}
      >
        {textLabel}
      </Typography>
    );
  };

  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">My Orders</Typography>
          <BreadcrumbsNav
            pathList={[
              { path: 'Home', route: ROUTES.DASHBOARD },
              {
                path: 'Order',
                route: ROUTES.ORDERHISTORY,
              },
              {
                path: 'Order Details',
                color: 'primary',
              },
            ]}
          />
        </>
      }
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: smBelow ? '0px' : '12px',
        }}
      >
        <Box p={3}>
          <Button
            startIcon={<ChevronLeftIcon />}
            onClick={() => {
              history.push(ROUTES.ORDERHISTORY);
            }}
          >
            Back
          </Button>
          <Box p={1} />
          {orderLoading ? (
            <div
              style={{
                width: '50%',
              }}
            >
              <EnhancedSkeleton
                layout={[
                  {
                    loaders: [{ variant: 'rect', height: 40, width: '100%' }],
                    repeat: 1,
                  },
                ]}
              />
              <List subheader={<li />}>
                {new Array(4).fill().map((x, i) => (
                  <ListItem
                    key={i}
                    style={{
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                        },
                        variant: 'body2',
                      }}
                      primary={
                        <EnhancedSkeleton
                          layout={[
                            {
                              loaders: [
                                { variant: 'rect', height: 20, width: '30%' },
                              ],
                              repeat: 1,
                            },
                          ]}
                        />
                      }
                    />
                  </ListItem>
                ))}
              </List>
              <Box p={3} />

              <EnhancedSkeleton
                layout={[
                  {
                    loaders: [{ variant: 'rect', height: 40, width: '100%' }],
                    repeat: 1,
                  },
                ]}
              />
              <List subheader={<li />}>
                {new Array(5).fill().map((x, i) => (
                  <ListItem
                    key={i}
                    style={{
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                        },
                        variant: 'body2',
                      }}
                      primary={
                        <EnhancedSkeleton
                          layout={[
                            {
                              loaders: [
                                { variant: 'rect', height: 20, width: '30%' },
                              ],
                              repeat: 1,
                            },
                          ]}
                        />
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            <>
              <Paper
                elevation={0}
                variant={smBelow ? 'elevation' : 'outlined'}
                style={{
                  borderRadius: smBelow ? '0px' : '4px',
                  width: '50%',
                }}
              >
                <List subheader={<li />}>
                  <ListItem
                    style={{
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          fontWeight: 'bold',
                        },
                        variant: 'body2',
                      }}
                      primary={`Order ID: #${order.id}`}
                    />
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                        },
                        variant: 'body2',
                      }}
                      primary={'Billing Name'}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="body2">User name</Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                        },
                        variant: 'body2',
                      }}
                      primary={'Date'}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="body2">
                        {moment(order.created_at).format(`DD-MM-yyyy hh:mma`)}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          fontWeight: 'bold',
                        },
                        variant: 'body2',
                      }}
                      primary={'Total Amount'}
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        style={{ fontWeight: 'bold' }}
                        variant="body2"
                      >
                        S$ {order.totalPrice}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                        },
                        variant: 'body2',
                      }}
                      primary={'Status'}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="body2">{order.status}</Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Paper>
              <Box p={3} />
              <Paper
                elevation={0}
                variant={smBelow ? 'elevation' : 'outlined'}
                style={{
                  borderRadius: smBelow ? '0px' : '4px',
                  width: '50%',
                }}
              >
                <List subheader={<li />}>
                  <ListItem
                    style={{
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          fontWeight: 'bold',
                        },
                        variant: 'body2',
                      }}
                      primary={`${
                        (order?.items && order?.items.length) || 0
                      } Item(s)`}
                    />
                  </ListItem>
                  <Divider />

                  {order?.items &&
                    order?.items.map((item) => (
                      <>
                        <ListItem>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                whiteSpace: 'normal',
                              },
                              variant: 'body2',
                            }}
                            primary={item?.product.name}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="body2">
                              S$ {item.price}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </>
                    ))}
                </List>
              </Paper>
            </>
          )}
        </Box>
      </Paper>
    </ContentLayout>
  );
};

export default OrderHistoryDetails;
