import React, { useContext } from 'react';
import { Paper } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Avatar from '@material-ui/core/Avatar';
import { Box, Typography } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useStyles from './InfoBox.styles';
import { ConfigContext } from 'src/contexts';

function truncate(str, num) {
  if (str) return str.length > 10 ? str.substring(0, num) + '...' : str;
}
const InfoBox = ({ vertical, width }) => {
  const { mdBelow } = useBreakpoints();
  const classes = useStyles();
  const { company } = useContext(ConfigContext);

  return (
    <Box
      display="flex"
      flexDirection={vertical ? 'column' : 'row'}
      width={width ? width : '100%'}
    >
      <Paper className={classes.paper}>
        <Box pr={3} />
        <Avatar className={classes.avatar1}>
          <DescriptionOutlinedIcon fontSize="large" />
        </Avatar>
        <Box pl={mdBelow ? 0 : 2} display="flex" flexDirection="column">
          <Box p={mdBelow ? 1 : 0}>
            <Typography
              noWrap
              variant="h3"
              numberOfLines={1}
              align={mdBelow ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              {truncate(company.company_name, 13)}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={mdBelow ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            {company.company_registration_no}
          </Typography>
        </Box>
      </Paper>
      <Box p={1} />
      <Paper className={classes.paper}>
        <Box pr={3} />
        <Avatar className={classes.avatar2}>
          <AttachMoneyRoundedIcon fontSize="large" />
        </Avatar>
        <Box pl={mdBelow ? 0 : 2} display="flex" flexDirection="column">
          <Box p={mdBelow ? 1 : 0}>
            <Typography
              variant="h3"
              align={mdBelow ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              {'$87,256'}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={mdBelow ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            {'Share Capital'}
          </Typography>
        </Box>
      </Paper>
      <Box p={1} />
      <Paper className={classes.paper}>
        <Box pr={3} />
        <Avatar className={classes.avatar3}>
          <PersonOutlineOutlinedIcon fontSize="large" />
        </Avatar>
        <Box pl={mdBelow ? 0 : 2} display="flex" flexDirection="column">
          <Box p={mdBelow ? 1 : 0}>
            <Typography
              variant="h3"
              align={mdBelow ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              {'156'}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={mdBelow ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            {'Total Employees'}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default InfoBox;
