import { Box } from '@material-ui/core';
import React from 'react';
import AuthLayout from 'src/components/AuthLayout';

const ContentLayout = ({ children, header }) => {
  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box
        px={4}
        py={2}
        display="flex"
        flexDirection="column"
        style={{ minHeight: '85%' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pb={2}
        >
          {header}
        </Box>

        {children}
      </Box>
    </AuthLayout>
  );
};

export default ContentLayout;
