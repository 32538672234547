import { Box, Typography } from '@material-ui/core';
import React from 'react';
import AuthLayout from 'src/components/AuthLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';

const Layout = ({ children }) => {
  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box
        px={4}
        py={2}
        display="flex"
        flexDirection="column"
        style={{ minHeight: '85%' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pb={2}
        >
          <Typography variant="h1">Request</Typography>
          <BreadcrumbsNav
            pathList={[
              { path: 'Home', route: ROUTES.DASHBOARD },
              {
                path: 'Corporate Secretary',
              },
              {
                path: 'Request',
                color: 'primary',
              },
            ]}
          />
        </Box>

        {children}
      </Box>
    </AuthLayout>
  );
};

export default Layout;
