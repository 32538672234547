import { Paper, Box, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ReqLayout from 'src/components/Corpsec/Requests/Layout';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ReqMenu from 'src/components/Corpsec/Requests/Menu';
import {
  CorpsecDashboard,
  CorpsecDocuments,
  CorpsecNameBox,
  CorpsecProfile,
  CorpsecRequests,
  CorpsecRequestsApptAdmin,
  CorpsecRequestsApptDirector,
  CorpsecRequestsChangeCompanyParticulars,
  CorpsecRequestsChangeCompanyName,
  CorpsecRequestsChangeCompanyFinancialYearEnd,
  CorpsecRequestsChangeCompanyAddress,
  CorpsecRequestsChangeBusinessActivities,
  CorpsecRequestsChangeShareInformation,
  CorpsecRequestsAllotmentShares,
  CorpsecRequestsTransferShares,
  CorpsecRequestsAllotmentSharesNew,
  CorpsecRequestsTransferSharesNew,
  CorpsecRequestsChangeOtherResolutions,
  CorpsecRequestsDeclareInterimDividend,
  CorpsecRequestsOpenBankAccount,
  CorpsecRequestsCloseBankAccount,
  CorpsecRequestsOtherRequest,
  CorpsecRequestsChangeDirectorParticulars,
  CorpsecRequestsChangeOfficers,
  CorpsecRequestsChangePersonalParticulars,
  CorpsecRequestsChangeShareholderParticulars,
  CorpsecRequestsDirectorList,
  CorpsecRequestsResgnAdmin,
  CorpsecRequestsResgnAdminForm,
  CorpsecRequestsResgnDirector,
  CorpsecRequestsResgnDirectorForm,
  CorpsecRequestsShareholderList,
  CorpsecSidebar,
  CorpsecTaskList,
  CorpsecBoardRoom,
  CorpsecCapTable,
} from 'src/components/Corpsec';

import ROUTES from 'src/constants/routes';

const Request = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(true);
  const params = useParams();
  const { smBelow } = useBreakpoints();

  const toggleShowMenu = () => {
    setShowMenu((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    if (location.pathname === ROUTES.CORPSEC_REQ) {
      setShowMenu(true);
    }
  }, [location]);

  const setContent = () => {
    switch (location.pathname) {
      case ROUTES.CORPSEC_REQ_APPT_ADMIN:
        return <CorpsecRequestsApptAdmin toggleShowMenu={toggleShowMenu} />;
      case ROUTES.CORPSEC_REQ_APPT_DIRECTOR:
        return <CorpsecRequestsApptDirector toggleShowMenu={toggleShowMenu} />;
      case ROUTES.CORPSEC_REQ_RESGN_ADMIN:
        return <CorpsecRequestsResgnAdmin toggleShowMenu={toggleShowMenu} />;
      case ROUTES.CORPSEC_REQ_RESGN_ADMIN_INFO(params.id):
        return (
          <CorpsecRequestsResgnAdminForm toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQ_RESGN_DIRECTOR:
        return <CorpsecRequestsResgnDirector toggleShowMenu={toggleShowMenu} />;
      case ROUTES.CORPSEC_REQ_RESGN_DIRECTOR_INFO(params.id):
        return (
          <CorpsecRequestsResgnDirectorForm toggleShowMenu={toggleShowMenu} />
        );

      case ROUTES.CORPSEC_REQ_DIRECTOR_LIST:
        return <CorpsecRequestsDirectorList toggleShowMenu={toggleShowMenu} />;
      case ROUTES.CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS(params.id):
        return (
          <CorpsecRequestsChangeDirectorParticulars
            toggleShowMenu={toggleShowMenu}
          />
        );
      case ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST:
        return (
          <CorpsecRequestsShareholderList toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQ_CHANGE_SHAREHOLDER_PARTICULARS(params.id):
        return (
          <CorpsecRequestsChangeShareholderParticulars
            toggleShowMenu={toggleShowMenu}
          />
        );

      case ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES:
        return (
          <CorpsecRequestsAllotmentShares toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQ_TRANSFER_SHARES:
        return (
          <CorpsecRequestsTransferShares toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES_NEW:
        return (
          <CorpsecRequestsAllotmentSharesNew toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQ_TRANSFER_SHARES_NEW:
        return (
          <CorpsecRequestsTransferSharesNew toggleShowMenu={toggleShowMenu} />
        );

      case ROUTES.CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND:
        return (
          <CorpsecRequestsDeclareInterimDividend
            toggleShowMenu={toggleShowMenu}
          />
        );
      case ROUTES.CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT:
        return (
          <CorpsecRequestsOpenBankAccount toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT:
        return (
          <CorpsecRequestsCloseBankAccount toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST:
        return <CorpsecRequestsOtherRequest toggleShowMenu={toggleShowMenu} />;

      case ROUTES.CORPSEC_REQ_CHANGE_COMPANY_NAME:
        return (
          <CorpsecRequestsChangeCompanyName toggleShowMenu={toggleShowMenu} />
        );
      case ROUTES.CORPSEC_REQ_CHANGE_COMPANY_ADDRESS:
        return (
          <CorpsecRequestsChangeCompanyAddress
            toggleShowMenu={toggleShowMenu}
          />
        );
      case ROUTES.CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES:
        return (
          <CorpsecRequestsChangeBusinessActivities
            toggleShowMenu={toggleShowMenu}
          />
        );
      case ROUTES.CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END:
        return (
          <CorpsecRequestsChangeCompanyFinancialYearEnd
            toggleShowMenu={toggleShowMenu}
          />
        );
    }
  };

  return (
    <ReqLayout>
      <Paper
        elevation={1}
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
        }}
      >
        {/* Desktop View */}
        {showMenu && <ReqMenu toggleShowMenu={toggleShowMenu} />}

        {/* Mobile View, either show menu or show content */}
        {smBelow && !showMenu && (
          <>
            <Divider orientation="vertical" flexItem />
            <Box style={{ flex: 6 }}>{setContent()}</Box>
          </>
        )}

        {/* Desktop View */}
        {!smBelow && (
          <>
            <Divider orientation="vertical" flexItem />
            <Box style={{ flex: 6 }}>{setContent()}</Box>
          </>
        )}
      </Paper>
    </ReqLayout>
  );
};

export default Request;
