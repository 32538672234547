import React from 'react';
import { ImageListItem, ImageListItemBar, Box } from '@material-ui/core';
import useStyles from './CardSlider.styles';
import { useHistory } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

var settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3.2,
  slidesToScroll: 2,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
};

const CardSlider = ({ itemData, overlay, styles }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box style={styles}>
      <Slider {...settings}>
        {itemData.map((item, index) => (
          <ImageListItem
            key={'ImageListItem' + index}
            onClick={() => {
              if (item.route) {
                history.push({
                  pathname: item.route,
                });
              }
            }}
            className={itemData.length !== index + 1 ? classes.gap : null}
          >
            <div
              className={classes.background}
              style={{
                backgroundImage: `url(${item.img})`,
              }}
            >
              <ImageListItemBar
                title={item.title}
                subtitle={item.subtitle}
                classes={{
                  root: overlay ? classes.backdrop : classes.itembar,
                  title: classes.title,
                  subtitle: classes.subtitle,
                }}
              />
            </div>
          </ImageListItem>
        ))}
      </Slider>
    </Box>
  );
};

export default CardSlider;
