import { Box, Typography } from '@material-ui/core';
import React from 'react';
import AuthLayout from 'src/components/AuthLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ROUTES from 'src/constants/routes';

const Layout = ({ children }) => {
  const current = new Date();
  const getDayToday = () => {
    let day;
    switch (current.getDay()) {
      case 0:
        day = 'Sunday';
        break;
      case 1:
        day = 'Monday';
        break;
      case 2:
        day = 'Tuesday';
        break;
      case 3:
        day = 'Wednesday';
        break;
      case 4:
        day = 'Thursday';
        break;
      case 5:
        day = 'Friday';
        break;
      case 6:
        day = 'Saturday';
        break;
    }
    return day;
  };

  return (
    // <AuthLayout
    //   contentStyle={{
    //     minWidth: 0,
    //     minHeight: 0,
    //   }}
    // >
    <Box
      px={4}
      py={2}
      display="flex"
      flexDirection="column"
      style={{ minHeight: '85%' }}
    >
      <Box display="flex" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pb={2}
        >
          <Typography variant="h1">Dashboard</Typography>
          <BreadcrumbsNav
            pathList={[
              { path: 'Home', route: ROUTES.DASHBOARD },
              {
                path: 'Secretarial',
              },
              {
                path: 'Dashboard',
                color: 'primary',
              },
              // {
              //   path: 'Request',
              //   color: 'primary',
              // },
            ]}
          />
        </Box>
        <div style={{ flexGrow: 1 }} />
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Typography
            variant="h2"
            style={{ color: '#808191', paddingBottom: 5 }}
          >
            {getDayToday()}
          </Typography>
          <Typography variant="h5">
            {current.getDate()},{' '}
            {current.toLocaleString('default', {
              month: 'long',
            })}{' '}
            {current.getFullYear()}
          </Typography>
        </Box>
      </Box>

      {children}
    </Box>
    // </AuthLayout>
  );
};

export default Layout;
