import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Tabs,
  Tab,
  InputAdornment,
  ButtonBase,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import Stepper from 'src/components/Stepper';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import DetailLabel from 'src/components/DetailLabel';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import { Icon } from '@iconify/react';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import useSnackbar from 'src/hooks/useSnackbar';
import { StringMasking } from 'src/utils/string';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TodayIcon from '@material-ui/icons/Today';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import DottedLabel from 'src/components/DottedLabel';
import RowTable from 'src/components/RowTable';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';

const tempSteps = [
  {
    id: 2,
    label: 'Transferor',
  },
  {
    id: 3,
    label: 'Transferee',
  },
];

const currencyList = [
  { label: 'EUR' },
  { label: 'GBP' },
  { label: 'JPY' },
  { label: 'SGD' },
  { label: 'USD' },
];

const shareholderList = [
  { label: 'Gideon' },
  { label: 'Edison' },
  { label: 'Tan' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: 'black',
    display: 'flex',
    alignItems: 'left',
    textAlign: 'left',
    backgroundColor: '#ECECEC',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));

const TransferShares = ({ toggleShowMenu }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { smBelow } = useBreakpoints();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [shareToTransfer, setShareToTransfer] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [countryCode, setCountryCode] = useState('SG');
  // const [isNewShareholder, setIsNewShareholder] = useState(false);
  const {
    addCart,
    user,
    cartSubmitting,
    setConfirmationDialog,
    company,
  } = useContext(ConfigContext);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    GetShareholderByUserID,
    shareholderError,
    shareholder,
    shareholders,
    newshareholder,
    ListShareholders,
    ApplyNewShareholder,
  } = useProvideShareholder();
  const {
    CreateShareTransferApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();

  useEffect(() => {
    // get latest product
    ReadProduct(21);
    GetShareholderByUserID(user.user_id);
    ListShareholders(company.company_id);
  }, []);

  // console.log('@@@ isNewShareholder', isNewShareholder);

  // useEffect(() => {
  //   if (newshareholder) {
  //     console.log('@@@ inside');
  //     setIsNewShareholder(!newshareholder);
  //   }
  // }, [newshareholder]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (shareholderError) {
      showErrorSnackbar(`Error loading shareholder info. ${shareholderError}`);
    }
  }, [shareholderError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const getShareholderByCompany = (shareholders = []) => {
    return shareholders.find((sh) => sh.company_id === company.company_id);
  };

  const step2validationSchema = Yup.object({
    number_of_share_transfer: Yup.number()
      .max(
        getShareholderByCompany(shareholder)?.user_allotment || 0,
        'Cannot be more than your share',
      )
      .required('Shares to Transfer is required'),
  });

  const step3validationSchema = Yup.object({
    transfer_to_user_id: Yup.string().when('newshareholder', {
      is: () => !newshareholder,
      then: Yup.string().required('Shareholder is required'),
    }),
  });

  const step4validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
  });

  const getStarted = {
    one: {
      content: [
        <Typography
          key={''}
          align="justify"
          variant="body2"
          color="textPrimary"
          display="inline"
        >
          {
            'Transfer of Ordinary Shares from existing shareholder to other existing or new'
          }
          <Typography
            align="justify"
            variant="body2"
            color="textPrimary"
            display="inline"
            className={classes.fontWeight}
          >
            {' Individual '}
          </Typography>
          {'shareholders'}
        </Typography>,
        'For transfer of other share type and currency, please submit under Other Request',
      ],
    },
    two: {
      content: [
        'Shareholder particulars & copy of NRIC/ Passport (For New Shareholder)',
        'Transfer Details (Number of Ordinary Shares & Considerations for each shareholder)',
      ],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['Basic Plan: SGD100', 'Unlimited Plan: SGD100'],
    },
    five: {
      content: [
        'Time to fill form: < 5 minutes.',
        'Average processing time: 10-15 days',
      ],
    },
  };

  const setShareholderOptions = (shareholders = []) => {
    return shareholders.map((sh) => ({
      label: sh.Name,
      value: sh.user_id,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmitStep2 = async (values, actions) => {
    setShareToTransfer(values.number_of_share_transfer);
    setStep(3);
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    values.transfer_from_shareholder_id = getShareholderByCompany(
      shareholder,
    )?.shareholder_id;
    values.number_of_share_transfer = shareToTransfer;
    values.is_new_shareholder = 0;
    if (newshareholder) {
      values.is_new_shareholder = 1;
      values = { ...values, ...newshareholder };
    }

    let res = await CreateShareTransferApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.share_transfer_application_id,
        company_id: company.company_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const formikOnSubmitNewShareholder = async (values, actions) => {
    setLoading(true);
    if (values.identityFiles.length > 0) {
      const identityFilesID = await S3UploadAndInsertDocuments(
        values.identityFiles[0],
        76,
        6,
        'officer_application_id',
      );
      values.proof_identity_attachment_id = identityFilesID.document_id;
    }

    if (values.residencyFiles.length > 0) {
      const residencyFilesID = await S3UploadAndInsertDocuments(
        values.residencyFiles[0],
        112,
        6,
        'officer_application_id',
      );
      values.proof_residency_attachment_id = residencyFilesID.document_id;
    }

    if (values.passportFiles.length > 0) {
      const passportFilesID = await S3UploadAndInsertDocuments(
        values.passportFiles[0],
        77,
        6,
        'officer_application_id',
      );
      values.passport_attachment_id = passportFilesID.document_id;
    }
    let res = await ApplyNewShareholder({
      company_id: company.company_id,
      company_application_status_id: 11,
      is_foreigner: tabValue == 0 ? 0 : 1,
      shareholder_name: values.name,
      identity_id: values.nric_passport_no,
      mobile_number: values.mobile_number,
      email: values.email,
      proof_identity_attachment_id: values.proof_identity_attachment_id,
      passport_attachment_id: values.passport_attachment_id,
      proof_residency_attachment_id: values.proof_residency_attachment_id,
      created_by: user.user_id,
    });
    setLoading(false);
    if (res.status === 'success') {
      actions.resetForm();
      setStep(3);
    }
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          number_of_share_transfer: '',
        }}
        onSubmit={formikOnSubmitStep2}
        validationSchema={step2validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Paper
              style={{
                width: '100%',
                borderRadius: 5,
                padding: '20px',
              }}
            >
              <Box display="flex" alignItems="center" pb={5}>
                <Icon
                  icon="carbon:user-avatar-filled"
                  color="black"
                  width="20"
                  height="20"
                />
                <Box p={1} />
                <Typography variant="h6" align="left">
                  {user.first_name} {user.last_name}
                </Typography>
              </Box>
              <Box display="flex" flexDirection={smBelow ? 'column' : 'row'}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={props.fullWidth ? '100%' : '50%'}
                >
                  <EnhancedTextField
                    disabled
                    labelText="Existing No. of Shares"
                    value={getShareholderByCompany(shareholder)?.user_allotment}
                    color="primary"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <FormTextField
                  name="number_of_share_transfer"
                  labelText="Shares to Transfer"
                  variant="outlined"
                  type="number"
                  width={smBelow ? '100%' : '70%'}
                />
                {!smBelow && <Box width="30%" />}
              </Box>
            </Paper>
            <Box display="flex" pt={3} alignItems="flex-start">
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Proceed'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = () => {
    return (
      <Formik
        initialValues={{
          transfer_to_user_id: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={step3validationSchema}
        enableReinitialize
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <DottedLabel
              label="Transfer From:"
              value={user.first_name + ' ' + user.last_name}
            />
            <Grid container>
              <RowTable
                label="Existing No. of Shares"
                latest={getShareholderByCompany(shareholder)?.user_allotment}
              />
              <RowTable label="Shares to Transfer" latest={shareToTransfer} />
              <RowTable
                label="Balance"
                latest={
                  getShareholderByCompany(shareholder)?.user_allotment -
                  shareToTransfer
                }
              />
            </Grid>

            <Box py={3} />
            <Box
              p={2}
              display="flex"
              flexDirection={smBelow ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={smBelow ? 'flex-start' : 'center'}
              width="100%"
            >
              <DottedLabel value="Transfer Details" />
              <ButtonBase
                style={{ justifyContent: 'flex-start', color: '#A21D23' }}
                onClick={() => {
                  setStep(4);
                }}
              >
                <Typography variant="h6">Add New Shareholder</Typography>
                <ChevronRightIcon />
              </ButtonBase>
            </Box>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                {newshareholder && (
                  <>
                    <DetailLabel
                      label="New Shareholder"
                      value={newshareholder.shareholder_name}
                    />
                    <Box p={1} />
                  </>
                )}
                {!newshareholder && (
                  <FormDropdown
                    autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
                    name="transfer_to_user_id"
                    onChange={(event, newValue) => {
                      props.setFieldValue(
                        'transfer_to_user_id',
                        newValue?.value,
                      );
                    }}
                    optionList={setShareholderOptions(shareholders)}
                    labelText="SELECT SHAREHOLDER"
                    placeholder="Select One"
                    fullWidth
                    containerWidth="50%"
                    shrink
                    variant="outlined"
                  />
                )}
                <FormDatePicker
                  containerWidth={smBelow ? '100%' : '71%'}
                  color="secondary"
                  name="transfer_date"
                  labelText="Date of Appointment"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                />
              </Box>
            </Paper>
            <Box p={2} />
            <Box display="flex" alignItems="flex-start">
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Submit'}
                loading={cartSubmitting || requestSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const addNewShareholder = () => {
    return (
      <>
        <Typography variant="h6" align="left" className={classes.fontWeight}>
          Fill in information for New Shareholder
        </Typography>
        <Box p={1} />
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          style={{
            borderBottom: '0.01rem solid #E4E4E4',
            paddingLeft: theme.spacing(smBelow ? 3 : 12),
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Local" />
          <Tab label="Foreigner" />
        </Tabs>
        <Box p={1} />
        <TabPanel value={tabValue} index={0}>
          {renderForm()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderForm()}
        </TabPanel>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmitNewShareholder}
        validationSchema={step4validationSchema}
      >
        {(props) => (
          <Box p={3} pl={smBelow ? 3 : 12}>
            <Form>
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="name"
                labelText="NAME (as in NRIC/FIN) *"
                placeholder="Name"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="nric_passport_no"
                labelText="ID *"
                placeholder="ID"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormDropdownTextField
                name="mobile_number"
                labelText="Mobile Number *"
                placeholder="91234567"
                containerWidth={smBelow ? '100%' : '50%'}
                fullWidth
                shrink
                variant="outlined"
                showFlag={false}
                onValueChange={(event, newValue) => {
                  setCountryCode(newValue.code);
                }}
                countryCode={countryCode}
              />
              <FormTextField
                fullWidth={smBelow ? true : false}
                name="email"
                labelText="Email Address *"
                placeholder="Email Address"
                shrink
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {tabValue === 0 && (
                <FormFileDropzone
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="identityFiles"
                  labelText="Proof of Identity *"
                />
              )}

              {tabValue === 1 && (
                <Box
                  display="flex"
                  flexDirection={smBelow ? 'column' : 'row'}
                  style={{
                    gap: '20px',
                  }}
                >
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="passportFiles"
                    labelText="Copy of Passport *"
                  />
                  <FormFileDropzone
                    containerWidth={smBelow ? '100%' : '50%'}
                    name="residencyFiles"
                    labelText="Proof of Residency (Must be in English) *"
                  />
                </Box>
              )}

              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  // disabled={!(props.isValid && props.dirty)}
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SAVE'}
                  onClick={() => {}}
                  loading={loading}
                />
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Transfer of Shares'}
              {(step === 2 || step === 3 || step === 4) && 'Transfer Details'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Share Information',
                  route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
                },
                {
                  path: 'Transfer of Shares',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step !== 1 && (
          <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
            {(step === 2 || step === 3) && (
              <Stepper
                steps={tempSteps}
                providedStep={step - 2}
                setProvidedStep={(step) => {
                  setStep(step + 2);
                }}
              />
            )}
            {step === 2 && stepTwo()}
            {step === 3 && stepThree()}
            {step === 4 && addNewShareholder()}
          </Box>
        )}
        <Box py={5} />
      </GetStartedLayout>
    </>
  );
};

export default TransferShares;
