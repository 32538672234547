import React, { createRef, useRef, forwardRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const PinField = (props) => {
  const {
    value = ['', '', '', '', '', ''],
    onChange = () => {},
    numberOfPins = 6,
    manageFocus = true,
  } = props;
  const inputRefs = useRef([]);

  if (value && value.length > 0) {
    inputRefs.current = value?.map(
      (_, i) => inputRefs.current[i] ?? createRef(),
    );
  }

  let pinInputs = [];
  for (let i = 0; i < numberOfPins; i++) {
    pinInputs.push(
      <Grid key={i} item xs={12 / numberOfPins}>
        <Box display="flex" justifyContent="center">
          <PinItem
            ref={inputRefs.current[i]}
            value={value?.[i]}
            onChange={(event) => {
              const eventValue = event.target.value;
              // in the case of an autocomplete or copy and paste
              if (eventValue.length > 2) {
                // see if we can use the string to fill out our values
                if (
                  eventValue.length === value.length &&
                  eventValue.match(/^[0-9]+$/)
                ) {
                  onChange(eventValue.split(''));
                }
                return;
              }
              // digit was deleted
              if (eventValue === '') {
                const newValues = value.slice();
                newValues[i] = '';
                onChange(newValues);
                return;
              }
              // we want to override the input value with the last digit typed
              const currentValue = value[i];
              let newValue = eventValue;
              if (currentValue[0] === eventValue[0]) {
                newValue = eventValue[1];
              } else if (currentValue[0] === eventValue[1]) {
                newValue = eventValue[0];
              }

              // only fire a change event if the new value is a digit
              if (newValue.match(/^[0-9]$/)) {
                const newValues = value.slice();
                newValues[i] = newValue;
                onChange(newValues);
                // tab to next pin code input if we aren't at end already
                if (manageFocus && i < value.length - 1) {
                  const inputRef = inputRefs.current[i + 1];
                  if (inputRef && inputRef.current && inputRef.current.focus) {
                    inputRef.current.focus();
                  }
                }
              }
            }}
            onKeyDown={(event) => {
              // if we see a backspace/delete and the input is empty, transfer focus backward
              if (
                manageFocus &&
                event.key === 'Backspace' &&
                value[i] === '' &&
                i > 0
              ) {
                const inputRef = inputRefs.current[i - 1];
                if (inputRef && inputRef.current && inputRef.current.focus) {
                  inputRef.current.focus();
                }
              }
            }}
          />
        </Box>
      </Grid>,
    );
  }
  // return value.map((aValue, index) => {
  //   return <TextField key={index} value={aValue} onChange={() => {}} />;
  // });
  return <Grid container>{pinInputs}</Grid>;
};

export default PinField;

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
}))(TextField);

const PinItem = forwardRef((props, ref) => {
  const { value, onChange, ...restProps } = props;
  return (
    <StyledTextField
      inputRef={ref}
      value={value}
      onChange={onChange}
      style={{ width: '100%', maxWidth: '45px', backgroundColor: '#fff' }}
      variant="outlined"
      inputProps={{
        maxLength: '1',
        pattern: /^[0-9]$/,
      }}
      {...restProps}
    />
  );
});
