import React from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import Autocomplete from '@material-ui/lab/Autocomplete';

const EnhancedDropdown = ({
  children,
  disabled,
  required,
  variant = 'standard',
  shrink = false,
  onChange,
  optionList,
  autoCompleteStyle,
  labelProps = {},
  defaultValue,
  ...props
}) => (
  <>
    <Box style={{ display: 'flex' }}>
      <Icon icon={props.icon} width="20" height="20" />
      <Typography
        variant="body2"
        align="left"
        style={{ paddingLeft: 5 }}
        {...labelProps}
      >
        {props.labelText}
      </Typography>
      <Typography
        variant="body2"
        align="left"
        style={{ paddingLeft: 5, fontStyle: 'italic', color: '#808080' }}
      >
        {props.optional && '(optional)'}
      </Typography>
    </Box>
    <Autocomplete
      style={autoCompleteStyle}
      options={optionList}
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          disabled={disabled}
          InputLabelProps={shrink ? { shrink } : {}}
          variant={variant}
          style={{ paddingBottom: 30 }}
        />
      )}
    />
  </>
);

export default EnhancedDropdown;
