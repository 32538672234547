import authSvc from './authentication';
import axios from './customAxios';
import storageSvc from './storage';
import { Config } from 'src/config';
const API_URL = Config.apiURL;

const companies = async () => {
  const auth = authSvc.getAuthData();
  const { data } = await axios.get(
    `${API_URL}/company/companies/user/${auth?.user?.user_id}`,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return data?.company;
};

const companiesAndShares = async () => {
  const auth = authSvc.getAuthData();
  let shares = [];
  let companies = [];
  try {
    const sharesRs = await axios.get(
      `${API_URL}/shareholder/users/${auth?.user?.user_id}/shareholders`,
    );

    if (sharesRs?.data?.is_success) {
      shares = sharesRs.data?.shares;
    }
  } catch (err) {}

  try {
    const { data } = await axios.get(
      `${API_URL}/company/companies/user/${auth?.user?.user_id}`,
    );
    if (data && !data.is_success) throw Error(data?.message);

    if (data.company?.length == 0) return [];

    if (shares?.length == 0) return data.company;

    const comps = [
      ...new Map(
        shares.concat(data.company).map((item) => [item['company_id'], item]),
      ).values(),
    ];
    companies = comps.map((c) => {
      let totalShares = 0;

      for (const s of shares.filter((s) => s.company_id == c.company_id)) {
        totalShares += s.user_allotment;
      }

      return {
        ...c,
        shares: totalShares === 0 ? '0' : totalShares,
      };
    });
  } catch (err) {}

  console.log(companies);

  return companies;
};

const getCompany = () => storageSvc.getItem('@selected_company');

const setCompany = (company) =>
  storageSvc.setItem('@selected_company', company);

const removeSelectedCompany = () => {
  storageSvc.deleteItem('@selected_company');
};

const companySvc = {
  companies,
  companiesAndShares,
  getCompany,
  setCompany,
  removeSelectedCompany,
};

export default companySvc;
